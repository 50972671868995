.navbar {
    background-color: #333;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar ul {
    list-style: none;
    display: flex;
    gap: 1rem;
  }
  
  .navbar ul li a, .contact-btn {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .contact-btn {
    background-color: #28a745;
  }
  
  .navbar ul li a:hover, .contact-btn:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .navbar ul {
      flex-direction: column;
      gap: 0.5rem;
    }
  
    .navbar ul li {
      width: 100%;
    }
  
    .navbar ul li a, .contact-btn {
      display: block;
      text-align: center;
    }
  }
  